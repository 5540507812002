@import "../../../../assets/styles/variables/index.scss";

.blog-card {
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid transparent !important;
  img {
    height: 180px;
    min-height: 180px;
    max-height: 180px;
    object-fit: cover;
  }

  &:hover {
    border: 1px solid theme-color("secondary") !important;
  }
}
