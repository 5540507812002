@import "../../../../assets/styles/variables/";

#app-download {
  position: relative;
  .mobile-img {
    width: 75%;
  }
  @include media-breakpoint-up(lg) {
    height: 480px;
    .mobile-img {
      width: 50%;
    }
  }

  .clipped-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url("../../../../../public/images/download-app-bg.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;

    // @include media-breakpoint-up(sm) {
    //   clip-path: polygon(0 0, 100% 30%, 100% 100%, 0 100%);
    //   &:lang(rtl) {
    //     clip-path: polygon(0 30%, 100% 0, 100% 100%, 0 100%);
    //   }
    // }

    @include media-breakpoint-up(lg) {
      clip-path: polygon(0 0, 100% 30%, 100% 100%, 0% 100%);
      &:lang(rtl) {
        clip-path: polygon(0 30%, 100% 0%, 100% 100%, 0% 100%);
      }
    }
  }
}
