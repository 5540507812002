@import "assets/styles/variables";

.service-card {
  position: relative;
  transition: all 0.2s ease;
  border: 1px solid transparent !important;

  .service_img {
    width: 100%;
    min-height: 300px;
    max-height: 300px;
    max-width: 100%;
    object-fit: cover;
  }

  .price-container {
    height: 46px;
  }

  @include media-breakpoint-up(xl) {
    .price-container {
      height: 40px;
    }
  }

  .favourite {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 10;
    &:lang(rtl) {
      right: unset;
      left: 10px;
    }
  }

  .service-title {
    min-height: 63px;
  }

  .service-description {
    min-height: 36px;
  }

  &:hover {
    border: 1px solid theme-color("secondary") !important;
  }
}
