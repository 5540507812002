@import "assets/styles/variables";
.order-item {
  .item_img {
    img {
      width: 100%;
      @include media-breakpoint-up(lg) {
        width: 195px !important;
        height: 139px !important;
      }
    }
  }
}
