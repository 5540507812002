@import "assets/styles/variables";

.mobile-bottom-nav {
  height: $mobile-bottom-nav-height;

  svg {
    width: 32px;
    height: 32px;
  }
  a {
    color: theme-color("gray7");
    &.active {
      color: theme-color("secondary");
    }
  }
}
