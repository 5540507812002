@import "assets/styles/variables";

.option-group {
  .option-item {
    border: 1px solid theme-color("secondary");

    &.chosen {
      border: 1px solid theme-color("primary");
    }
  }
}
