@import "assets/styles/variables";

#ServiceListPage {
  .sideSwipe {
    width: 11px;
    height: 200px;
    z-index: 10;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
