@import "../../../../assets/styles/variables/";

.footer-details-container {
  background: theme-color("gray3");
  .logo {
    min-width: 300px;
    max-width: 300px;
  }

  .title {
    position: relative;
    padding-bottom: 14px;
    &::after {
      content: "";
      position: absolute;
      width: 27px;
      height: 4px;
      bottom: 0;
      left: 0;
      background-color: theme-color("secondary");
    }
    &:lang(rtl) {
      &::after {
        left: unset;
        right: 0;
      }
    }
  }

  .social-icons {
    img {
      width: 36px;
      height: 36px;
    }
  }

  .payment-methods {
    img {
      width: 64px;
      height: 64px;
      object-fit: contain;
    }
  }
}
