@import "../../assets/styles/variables";

.map-component {
  position: relative;
  height: 100%;
  &__map {
    &--with-marker:after {
      content: " ";
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 60px;
      height: 60px;
      background: url("../../../public/images/marker.svg");
      background-size: 60px 60px;
      transform: translateX(-50%) translateY(-50%);
      pointer-events: none;
    }
  }
  &__header {
    position: absolute;
    top: 16px;
    left: 0;
    width: 100%;
    @include media-breakpoint-up(lg) {
      left: 50%;
      transform: translateX(-50%);
      width: auto;
    }
    z-index: 30000;
  }
}
