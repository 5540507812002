@import "assets/styles/variables";
.quantity-counter {
  .btn {
    &:hover {
      color: theme-color("primary");
    }
  }
  input {
    &:hover,
    &:active,
    &:focus {
      color: theme-color("secondary") !important;
      background-color: initial;
    }
  }
}
