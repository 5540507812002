html {
  min-width: 320px;
  overflow-x: hidden;

  &:lang(rtl) {
    direction: rtl;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-size: 14px;
}

html,
body,
#root {
  overflow-x: hidden;
  // height: 100%;
  // display: block;
  margin: 0 !important;
  padding: 0 !important;
  // position: static;
  min-height: 100vh;
  // width: 100%;
}

a {
  text-decoration: none !important;
  color: unset;
  &:hover {
    color: unset;
  }
}

p {
  padding: 0;
  margin: 0;
}

li,
ul {
  padding: 0;
}
