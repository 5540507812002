.text-autocomplete {
  position: relative;

  &__list {
    transform-origin: top center;
    display: none;
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    width: 100%;
    z-index: 100;
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow-y: scroll;
  }
}
