@import "assets/styles/variables";

#ProfileTabs {
  .user-info {
    .user-img {
      .edit-icon {
        width: 18px;
        height: 18px;
      }
    }
  }
  .profile_nav {
    .profile_nav-item {
      .nav-item {
        color: theme-color("dark");
        svg {
          color: theme-color("primary");
        }
        &:hover {
          color: theme-color("primary");
        }
      }
      &.active {
        background-color: theme-color("secondary");
        svg {
          color: theme-color("white");
        }
        .nav-item {
          color: theme-color("white");
        }
      }
    }
  }
}
