@import "assets/styles/variables";

#our-services-page {
  .grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 15px;
    padding-bottom: 80px; // 30px*2
    .grid-item {
      aspect-ratio: 1;
    }
  }
}
