@import "assets/styles/variables";

#our-services-page {
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(5, 1fr);
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(7, 1fr);
    }

    .h-100-lg {
      @include media-breakpoint-up(lg) {
        height: 100%;
      }
    }

    .grid-item {
      aspect-ratio: 1;
    }

    .service-category-card {
      // margin-top: -40px;
      @include media-breakpoint-up(lg) {
        &:nth-child(n + 8) {
          margin-top: 60px;
        }
      }
    }
  }
}
