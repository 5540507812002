@import "assets/styles/variables";

.footer-subscribe {
  background-image: url("../../../../../public/images/footer-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  .content {
    background: theme-color-opacity("secondary", 0.4);
  }
}
