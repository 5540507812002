@import "../../assets/styles/variables/";

#footer {
  .floating-actions {
    position: fixed;
    bottom: 20px;
    right: 40px;
    z-index: 100;
    &:lang(rtl) {
      right: unset;
      left: 40px;
    }

    &__item,
    &__item * {
      height: 80px;
      width: 80px;
    }
  }
  .footer-details-container {
    a:hover {
      color: theme-color(secondary) !important;
    }
  }
  .copy-right {
    a:hover {
      color: theme-color(gray2) !important;
    }
  }
}
