@import "../../../../assets/styles/variables/";

#clients-testimonials {
  position: relative;

  .custom-slider .swiper-pagination-bullets {
    .swiper-pagination-bullet {
      background-color: theme-color("white");
    }
  }
  .clipped-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url("../../../../../public/images/testimonials-bg.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;

    clip-path: polygon(0 50%, 100% 0, 100% 100%, 0 100%);
    &:lang(rtl) {
      clip-path: polygon(0 0, 100% 50%, 100% 100%, 0 100%);
    }
    @include media-breakpoint-up(lg) {
      clip-path: polygon(0 100%, 100% 0, 100% 100%, 0 100%);
      &:lang(rtl) {
        clip-path: polygon(0 0, 100% 100%, 100% 100%, 0 100%);
      }
    }
  }
  .clients-testimonials-form {
    padding-top: 70px;
    &::before {
      width: 75px;
      height: 70px;
      background-image: url(../../../../../public/images/qoute.svg);
      background-repeat: no-repeat;
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: -10px;
    }
  }
}
