@import "../../assets/styles/variables/index.scss";

#home-page {
  .section-mt {
    margin-top: 50px !important;
    @include media-breakpoint-up(lg) {
      margin-top: 95px !important;
    }
  }
  .section-mt {
    margin-top: 50px !important;

    @include media-breakpoint-up(lg) {
      margin-top: 38px !important; // 38px = 1cm apprx.
    }
  }

  .home-advertisement {
    height: 265px; // 265px = 7cm apprx.
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
