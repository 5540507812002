@import "assets/styles/variables";

#EditProfilePage {
  .item_img {
    img {
      width: 100%;
      @include media-breakpoint-up(md) {
        width: 183px;
        height: 160px !important;
      }
    }
  }
}
