@import "assets/styles/variables";

.date-picker {
  height: 310px !important;
  .react-calendar {
    width: 100%;
    border: 0;

    .react-calendar__navigation {
      button:disabled {
        background-color: transparent;
      }
    }

    .react-calendar__navigation__next2-button {
      display: none;
    }

    .react-calendar__navigation__prev2-button {
      display: none;
    }

    .react-calendar__year-view__months {
      max-height: 100%;
    }

    .react-calendar__year-view .react-calendar__tile,
    .react-calendar__decade-view .react-calendar__tile,
    .react-calendar__century-view .react-calendar__tile {
      padding: 1.25em 0.5em !important;
    }

    .react-calendar__month-view {
      .react-calendar__month-view__weekdays {
        .react-calendar__month-view__weekdays__weekday {
          abbr {
            font-weight: 900 !important;
            text-decoration: none;
          }

          &:nth-last-child(1),
          &:nth-last-child(2) {
            color: theme-color("secondary") !important;
          }
        }
      }

      .react-calendar__month-view__days__day {
        font-weight: 300 !important;

        &:not(:disabled) {
          font-weight: 700 !important;
          color: theme-color("black");
        }
        &:disabled {
          color: theme-color("gray7") !important;
        }
      }
    }

    .react-calendar__tile {
      &--now:not(:hover),
      &:disabled {
        background-color: transparent;
      }
      &--now:enabled:focus {
        background-color: transparent;
      }
      &--now:enabled:hover,
      &:not(:disabled):hover {
        background-color: theme-color("gray4");
      }
      &--active {
        background-color: theme-color("primary") !important;
        color: theme-color("white") !important;
      }
    }
  }
}
