@import "assets/styles/variables";

.otp-input {
  height: 60px;
  width: 100%;
  border: 1px solid theme-color("gray5");
  font-size: 21px;
  text-align: center;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  @include media-breakpoint-up(sm) {
    height: 80px;
  }

  @include media-breakpoint-up(lg) {
    height: 70px;
  }
}
