@import "assets/styles/variables/index.scss";

.advice-details-page {
  transition: all 0.2s ease;
  border: 1px solid transparent !important;
  img {
    height: 180px;
    min-height: 180px;
    max-height: 180px;
    object-fit: cover;
  }
}
