#header-section-one {
  height: 50px;
  a:hover {
    color: #fff !important;
  }

  .social-icon {
    filter: brightness(0) invert(1);
    width: 22px;
    height: 22px;
    object-fit: contain;
  }
}
