@import "../../assets/styles/variables";

$heading-tag-margin-bottom: 8px;
$heading-tag-border-bottom-width: 1px;
$border-bottom-width-to-heading-psuedo-after-ratio: 2;

.heading-component {
  border-bottom: $heading-tag-border-bottom-width solid theme-color("gray5");
  .heading-tag {
    display: inline-block;
    position: relative;
    margin-bottom: $heading-tag-margin-bottom;
    &:after {
      content: "";
      position: absolute;
      height: $heading-tag-border-bottom-width *
        $border-bottom-width-to-heading-psuedo-after-ratio;
      width: 105%;
      bottom: -($heading-tag-margin-bottom + $heading-tag-border-bottom-width);
      background: theme-color("primary");
      left: 0;
    }
    &:lang(rtl) {
      &:after {
        left: unset;
        right: 0;
      }
    }
  }
}
