@import "assets/styles/variables";
#header-section-three {
  height: 70px;

  .action-icons {
    &-count {
      position: absolute;
      top: -5px;
      right: -10px;
      width: 20px;
      height: 20px;
      display: flex;

      font-size: 0.7rem;
      position: absolute;
      top: -10px;
      right: -10px;
      width: 18px;
      height: 18px;
      color: theme-color("white");
      font-weight: 800;
      background-color: theme-color("primary");
      border-radius: 50%;
    }
  }
}
