@import "assets/styles/variables";

#EditProfilePage {
  .subscription_img {
    img {
      width: 100%;
      @include media-breakpoint-up(md) {
        width: 117px;
        height: 115px !important;
      }
    }
  }
}
