@import "../../assets/styles/variables/index.scss";

#Register__section {
  @include media-breakpoint-up(lg) {
    background-image: url("../../../public/images/Register_img.png");
    background-size: 50% 100%;
    background-repeat: no-repeat;
    &:lang(rtl){
      background-position: top right;
    }
  }
}
