.pagination-component {
  .page-btn {
    width: 30px;
    height: 30px;
    padding: 3px 7px;
    border-radius: 15px;
    font-size: 15px;
    text-align: center;
  }

  .page-prev,
  .page-next {
    .icon {
      width: 16px;
    }
  }
}
