@import "assets/styles/variables/index.scss";

#home-hero {
  .carousel {
    @include media-breakpoint-up(xs) {
      height: 180px;
    }
    @include media-breakpoint-between(sm, lg) {
      height: 360px;
    }
    @include media-breakpoint-up(lg) {
      height: 480px;
    }
    @include media-breakpoint-up(xl) {
      height: calc(100vh - (75px + 210px));
    }

    .custom-slider,
    .swiper {
      height: 100%;
      padding-bottom: 0;

      .swiper-pagination-bullets {
        position: absolute !important;
        bottom: 10px;
        .swiper-pagination-bullet {
          background-color: theme-color("white");
          &-active {
            background-color: theme-color("secondary");
          }
        }
        @include media-breakpoint-up(lg) {
          // bottom: 130px;
        }
      }
    }
    .slide {
      .slide_bg {
        display: block;
        object-fit: cover;
        z-index: 1;
      }

      .slide_container {
        position: relative;
        z-index: 10;
        &.content-left {
          float: left;
          margin-left: 26px;
          &:lang(rtl) {
            float: right;
            margin-left: unset;
            margin-right: 26px;
          }
        }
        &.content-center {
          width: 100%;
          display: flex;
          justify-content: center;
        }
        &.content-right {
          float: right;
          margin-right: 26px;
          &:lang(rtl) {
            float: left;
            margin-right: unset;
            margin-left: 26px;
          }
        }
        // float: left;
        // &:lang(rtl) {
        //   float: right;

        //   margin-left: unset;
        //   &:not(.centered) {
        //     margin-right: 26px;
        //   }
        // }
        .slide_content {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          &:lang(rtl) {
            left: unset;
            right: 50%;
            transform: translate(50%, -50%);
          }
          .title,
          .subtitle {
            font-weight: 900;
          }
          @include media-breakpoint-up(xs) {
            .title {
              font-size: 27px;
            }
            .subtitle {
              font-size: 14px;
            }
            .btn {
              font-size: 9px;
              padding-left: 2px;
              padding-right: 2px;
            }
          }
          @include media-breakpoint-between(sm, lg) {
            .title {
              font-size: 40px;
            }
            .subtitle {
              font-size: 21px;
            }
            .btn {
              font-size: 13px;
              padding-left: 10px;
              padding-right: 10px;
            }
          }
          @include media-breakpoint-up(lg) {
            .title {
              font-size: 51px;
            }
            .subtitle {
              font-size: 27px;
            }
            .btn {
              font-size: 17px;
              padding-left: 10px;
              padding-right: 10px;
            }
          }
          @include media-breakpoint-up(xl) {
            .title {
              font-size: 74px;
            }
            .subtitle {
              font-size: 40px;
            }
            .btn {
              font-size: 23px;
            }
          }
        }
      }
    }
  }

  .home-info {
    position: relative;
    z-index: 10;
    .home-info_container {
      height: 150px;
      min-height: 150px;
      max-height: 150px;
      // margin-top: -75px; // -75px;
      margin-top: 18px; // 18px = 0.5cm;

      img {
        height: 75px;
      }
    }
  }
}
