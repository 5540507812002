@import "assets/styles/variables";

#ProfileLinks {
  .items {
    .item.nav-item {
      .title {
        color: theme-color("gray7");
      }
      svg * {
        fill: theme-color("gray7");
      }
      &:hover {
        background-color: theme-color("primary");
        .title {
          color: theme-color("white");
        }
        svg * {
          fill: theme-color("white");
        }
      }
    }
  }
}
