@import "assets/styles/variables";
.filterbar {
  .accordion {
    .accordion-item {
      border: 0;

      * {
        text-transform: capitalize;
      }
      .accordion-header {
        .accordion-button {
          font-weight: 900;
          background-color: theme-color("secondary");
          color: theme-color("white");
          border-radius: 0;
          padding: 5px 15px;
          &::after {
            background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFFFFF%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
          }
        }
      }
    }
  }
}
