@import "assets/styles/variables";

.custom-slider {
  z-index: 1;
  .swiper {
    padding-bottom: 40px;
    .swiper-pagination {
      bottom: 0;
      .swiper-pagination-bullet {
        background-color: theme-color("secondary");
        opacity: 1;
        margin-right: 10px;
        margin-left: 10px;
        &-active {
          background-color: theme-color("secondary");
          border-radius: 8px;
          width: 40px;
        }
      }
    }
  }
}
