@import "assets/styles/variables";

.time-picker {
  .swiper {
    width: 100%;
    height: calc(300px - 64px) !important;

    .swiper-slide {
      text-align: center;
      background: #fff;
      touch-action: pan-y;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      &.swiper-slide-active::after {
        content: "";
        position: absolute;
        bottom: 5%;
        width: 35%;
        height: 1px;
        background-color: theme-color("secondary");
      }
    }
  }
}
