@import "assets/styles/variables";

.request-callout-modal {
  @include media-breakpoint-up(lg) {
    width: 60% !important;
    max-width: 60% !important;
  }

  @include media-breakpoint-up(xl) {
    width: 50% !important;
    max-width: 50% !important;
  }
}
