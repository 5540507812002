@import "assets/styles/variables";

.service-details {
  .attached-image {
    position: relative;

    .delete {
      position: absolute;
      top: 2px;
      z-index: 10;
      width: 18px;
    }
    img {
      width: 86px;
      height: 86px;
      object-fit: cover;
    }
  }

  .floating-price {
    border-top: 1px solid theme-color("gray4");
    background-color: theme-color("white");
    position: fixed;
    bottom: $mobile-bottom-nav-height;
    left: 0;
    width: 100%;
    padding: 1rem;

    @include media-breakpoint-up(lg) {
      bottom: 0;
    }
  }
}
