@import "../../assets/styles/variables";

#BreadCrumb {
  .image-container {
    height: 190px; // 190px = 5cm apprx.
    min-height: 190px; // 190px = 5cm apprx.
    max-height: 190px; // 190px = 5cm apprx.
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  ol {
    margin-bottom: 0;
  }

  .breadcrumb {
    .breadcrumb-item {
      a,
      &.active {
        color: theme-color("black") !important;
        text-decoration: none;
      }

      & + .breadcrumb-item {
        &:lang(rtl) {
          padding-left: unset;
          padding-right: 0.5rem;
          &:before {
            float: right;
            padding-right: unset;
            padding-left: 0.5rem;
          }
        }
      }
    }
  }
}
