@import "assets/styles/variables";

.btn {
  box-shadow: none !important;

  &.disabled,
  &:disabled {
    color: theme-color("white") !important;
    background: theme-color("gray7") !important;
    border: theme-color("gray7") !important;
  }

  &-primary {
    color: theme-color("white") !important;
    background: theme-color("primary");

    &:hover {
      color: theme-color("white");
      background: shade-color(theme-color("primary"), 5%);
    }

    &:active {
      color: theme-color("white");
      background: shade-color(theme-color("primary"), 2%);
    }

    &:focus {
      color: theme-color("white");
      background: theme-color("primary");
    }
  }

  &-secondary {
    color: theme-color("white");
    background: theme-color("secondary");

    &:hover {
      color: theme-color("white");
      background: shade-color(theme-color("secondary"), 5%);
    }

    &:active {
      color: theme-color("white");
      background: shade-color(theme-color("secondary"), 2%);
    }

    &:focus {
      color: theme-color("white");
      background: theme-color("secondary");
    }
  }

  &-success {
    color: theme-color("white");
    background: theme-color("success");

    &:hover {
      color: theme-color("white");
      background: shade-color(theme-color("success"), 5%);
    }

    &:active {
      color: theme-color("white");
      background: shade-color(theme-color("success"), 2%);
    }

    &:focus {
      color: theme-color("white");
      background: theme-color("success");
    }
  }

  &-info {
    color: theme-color("white");
    background: theme-color("info");

    &:hover {
      color: theme-color("white");
      background: shade-color(theme-color("info"), 5%);
    }

    &:active {
      color: theme-color("white");
      background: shade-color(theme-color("info"), 2%);
    }

    &:focus {
      color: theme-color("white");
      background: theme-color("info");
    }
  }

  &-warning {
    color: theme-color("white");
    background: theme-color("warning");

    &:hover {
      color: theme-color("white");
      background: shade-color(theme-color("warning"), 5%);
    }

    &:active {
      color: theme-color("white");
      background: shade-color(theme-color("warning"), 2%);
    }

    &:focus {
      color: theme-color("white");
      background: theme-color("warning");
    }
  }

  &-danger {
    color: theme-color("white");
    background: theme-color("danger");

    &:hover {
      color: theme-color("white");
      background: shade-color(theme-color("danger"), 5%);
    }

    &:active {
      color: theme-color("white");
      background: shade-color(theme-color("danger"), 2%);
    }

    &:focus {
      color: theme-color("white");
      background: theme-color("danger");
    }
  }

  &-outline-primary {
    color: theme-color("secondary");
    background: transparent;
    border-color: theme-color("primary");

    &:hover {
      color: theme-color("white");
      background: theme-color("primary");
    }

    &:active {
      color: theme-color("white");
      background: shade-color(theme-color("primary"), 2%);
    }

    &:focus {
      color: theme-color("white");
      background: theme-color("primary");
    }
  }

  &-outline-secondary {
    color: theme-color("secondary");
    border-color: theme-color("secondary");
    background: transparent;

    &:hover {
      color: theme-color("white");
      background: theme-color("secondary");
    }

    &:active {
      color: theme-color("white");
      background: shade-color(theme-color("secondary"), 2%);
    }

    &:focus {
      color: theme-color("white");
      background: theme-color("secondary");
    }
  }

  &-outline-warning {
    color: theme-color("warning");
    border-color: theme-color("warning");
    background: transparent;

    &:hover {
      color: theme-color("white");
      background: theme-color("warning");
    }

    &:active {
      color: theme-color("white");
      background: shade-color(theme-color("warning"), 2%);
    }

    &:focus {
      color: theme-color("white");
      background: theme-color("warning");
    }
  }

  &-outline-info {
    color: theme-color("info");
    border-color: theme-color("info");
    background: transparent;

    &:hover {
      color: theme-color("white");
      background: theme-color("info");
    }

    &:active {
      color: theme-color("white");
      background: shade-color(theme-color("info"), 2%);
    }

    &:focus {
      color: theme-color("white");
      background: theme-color("info");
    }
  }

  &:disabled {
    color: theme-color("white");
    background: theme-color("danger");
  }

  &-transparent {
    background-color: transparent;
    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
    }
  }
}
