@import "../../../../assets/styles/variables/index.scss";

#how-it-works {
  .how-it-works-body {
    @include media-breakpoint-up(lg) {
      background-color: theme-color("secondary");
      // height: 265px; // 265px = 7cm apprx.
      height: 240px; // 240px = 6.35 apprx.
    }

    @include media-breakpoint-up(xl) {
      background-color: theme-color("secondary");
      // height: 300px; // 300px = 8cm apprx.
      height: 240px; // 240px = 6.35 apprx.
    }

    .left-side {
      .steps-image {
        max-width: 100%;
      }
    }
    .right-side {
      @include media-breakpoint-up(sm) {
        position: relative;
        width: 100%;
      }
      @include media-breakpoint-up(lg) {
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        width: 50%;
        &:lang(rtl) {
          left: unset;
          right: 50%;
        }
      }
      @include media-breakpoint-up(xl) {
        left: unset;
        right: 0;
        width: 50%;
        &:lang(rtl) {
          left: 0;
          right: unset;
        }
      }
      .video_image {
        object-fit: cover;
      }
    }
  }
}
