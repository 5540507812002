@import "assets/styles/variables";

#contact-us-page {
  position: relative;
  .left-side {
    height: 300px;
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;

      &:lang(rtl) {
        left: unset;
        right: 0;
      }
    }
  }
  .social-icon {
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
}
