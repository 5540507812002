@import "../../../../assets/styles/variables/";

.cart-card-container {
  position: relative;
  .delete_icon {
    position: absolute;
    top: 4px;
    right: 4px;
    &:lang(rtl) {
      right: unset;
      left: 4px;
    }
  }

  height: 265px;
  max-height: 265px;
  overflow: hidden;
  @include media-breakpoint-up(lg) {
    height: auto;
    max-height: auto;
  }
  .img_container {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      max-height: 240px;
      @include media-breakpoint-up(lg) {
        max-height: unset;
        height: 100%;
      }
      object-fit: cover;
    }
  }
}
