@import "assets/styles/variables";
@import "assets/styles/base/functions";

.rating-list {
  padding-top: 10px;
  min-height: 480px;
  max-height: 480px;
  overflow-y: scroll;
  overflow-x: hidden;
  direction: rtl;
  padding-left: 10px;
  &:lang(rtl) {
    padding-right: 10px;
    direction: ltr;
  }
  * {
    direction: ltr;
    &:lang(rtl) {
      direction: rtl;
    }
  }

  &::-webkit-scrollbar {
    border-radius: 3px;
    width: 6px;
    height: 6px;
    background-color: theme-color-opacity("secondary", 1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: theme-color-opacity("white", 0.7) !important;
    &:hover {
      background-color: theme-color-opacity("white", 0.7) !important;
    }
  }
}
