@import "../../../assets/styles/variables/";

.package-card,
.package-card .package-card-content {
  border-radius: border-radius-single(lg);

  .title {
    height: 40px;
    @include media-breakpoint-up(lg) {
      height: 60px;
    }
  }

  border-radius: border-radius-single(lg);
  @include media-breakpoint-up(lg) {
    border-radius: border-radius-single(xxl);
  }

  .features {
    height: 100px;
    min-height: 100px;
    max-height: 100px;
    overflow: hidden;
  }

  .package-img {
    @include media-breakpoint-down(sm) {
      height: 56px;
    }
    object-fit: contain;
  }
}
