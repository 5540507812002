@import "assets/styles/variables";

#our-facts {
  background-image: url("../../../../../public/images/facts-bg.png");

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  .facts-service-link {
    color: theme-color("white");
    &:hover {
      color: theme-color("primary");
    }
  }
}
