.prompt-modal-fullscreen {
  .prompt-icon {
    width: 200px;
    height: 200px;
  }
  &__backdrop {
    opacity: 0.9 !important;
    background-color: white !important;
  }
}
