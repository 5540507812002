@import "assets/styles/variables";

.horizontal-option-field {
  .option-item {
    border: 1px solid theme-color("secondary");

    &.chosen {
      span {
        color: white;
      }
      background-color: theme-color("secondary");
      border: 1px solid theme-color("secondary");
    }
  }
}
