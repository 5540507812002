.offer-card {
  height: 400px;
  img {
    width: 100%;
    min-height: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}
