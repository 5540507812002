@import "assets/styles/variables/index.scss";

.service-category-card {
  text-align: center;

  .image-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 75px;
    height: 75px;
    transition: 400ms;

    img {
      object-fit: contain;
      width: 50px;
      height: 50px;
    }

    @include media-breakpoint-up(sm) {
      width: 100px;
      height: 100px;

      img {
        width: 40px;
        height: 40px;
      }
    }

    @include media-breakpoint-up(lg) {
      width: 100%;
      height: 100%;

      img {
        width: 85px;
        height: 85px;
      }
    }

    @include media-breakpoint-up(xl) {
      width: 100%;
      height: 100%;

      img {
        width: 100px;
        height: 100px;
      }
    }
  }
}
