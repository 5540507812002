@import "../../assets/styles/variables/index.scss";

#Login__section {
  @include media-breakpoint-up(lg) {
    background-image: url("../../../public/images/login_img.jpeg");
    background-size: 50% 100%;
    background-repeat: no-repeat;
    &:lang(rtl){
      background-position: top right;
    }
  }
}
