@import "assets/styles/variables";

.advice-card {
  overflow: hidden;
  .img_container {
    height: 300px;
    min-height: 300px;
    max-height: 300px;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
