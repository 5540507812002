@import "assets/styles/variables";

#header-section-two {
  height: 70px;

  .logo {
    img {
      min-width: 200px;
      max-width: 200px;
    }
  }

  .header-navigation {
    .navbar {
      @include media-breakpoint-between(md, xl) {
        .navbar-collapse {
          position: absolute;
          top: 50px;
          width: 200px;
          background: white;
          border: 1px solid theme-color("gray5");
          padding: 10px;
          z-index: 10;
          border-radius: 12px;
          .nav-link {
            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }
        }
      }

      .navbar-nav {
        height: 100% !important;
        a {
          span {
            font-size: 17px;
            color: theme-color("dark");
            font-weight: normal;
          }
          &:hover {
            span {
              color: theme-color("secondary");
            }
          }
          &.active {
            span {
              color: theme-color("secondary");
              font-weight: bolder;
            }
          }
        }
      }
    }
  }

  .user-dropdown {
    .user-icon {
      height: 42px;
      width: 42px;
    }

    .dropdown-menu {
      // width: 100%;
      margin-top: 10px;

      border-top-left-radius: 0;
      border-top-right-radius: 0;
      .dropdown-item-button {
        border-radius: 0;
        color: theme-color("black");
        svg {
          color: theme-color("primary");
          width: 26px;
          height: 26px;
        }

        &:hover,
        &:focus,
        &:active {
          color: theme-color("white");
          svg {
            color: theme-color("white");
          }
          background-color: theme-color("secondary");
        }
      }
    }
  }

  .btn-group.auth-btn-group {
    .btn {
      box-shadow: none !important;

      color: theme-color("dark");
      &:hover {
        color: theme-color("white");
      }
      &:first-child {
        border-right-width: 0px;
        border-top-left-radius: 12px !important;
        border-bottom-left-radius: 12px !important;
        &:lang(rtl) {
          border-right-width: 1px;
          border-left-width: 0px;
          border-top-right-radius: 12px !important;
          border-bottom-right-radius: 12px !important;
          border-top-left-radius: unset !important;
          border-bottom-left-radius: unset !important;
        }
      }

      &:last-child {
        border-left-width: 0px;
        border-top-right-radius: 12px !important;
        border-bottom-right-radius: 12px !important;
        &:hover {
          background-color: theme-color("primary");
        }
        &:lang(rtl) {
          border-right-width: 0px;
          border-left-width: 1px;
          border-top-left-radius: 12px !important;
          border-bottom-left-radius: 12px !important;
          border-top-right-radius: unset !important;
          border-bottom-right-radius: unset !important;
        }
      }
    }

    &:after {
      content: "";
      z-index: 2;
      background-color: theme-color("black");
      position: absolute;
      top: 26%;
      left: 56%;
      width: 1px;
      height: 16px;
    }
    &:lang(rtl) {
      &:after {
        left: 50%;
      }
    }
    &:hover {
      &:after {
        display: none;
      }
    }
  }
}
