.custom-dropdown {
  .dropdown-toggle::after {
    display: none;
  }
  .btn:hover {
    color: white;
  }
  .dropdown-menu {
    min-width: 200px !important;
  }
}
