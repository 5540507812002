@import "../../assets/styles/variables";

.title-component {
  @include media-breakpoint-up(lg) {
    .title,
    .description {
      text-align: center;
    }
  }
}
