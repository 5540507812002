.otp-container {
  span {
    display: none;
  }

  button {
    border: none;
    background-color: transparent;
    color: blue;
    font-size: large;
  }
}

.input_OTP {
  width: 50px !important;
  height: 50px !important;
  margin-right: 35px !important;

  @media (max-width: 425px) {
    width: 40px !important;
    height: 40px !important;
    margin-right: 20px !important;
  }

}
