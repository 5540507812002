.read-more {
  h4 {
    transition: all 0.5s ease;
    margin-inline-end: 10px;
  }

  &:hover {
    h4 {
      margin-inline-end: 20px;
    }
  }
}
