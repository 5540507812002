@import "assets/styles/variables";

.top-rated-services {
  .service-card {
    //    max 576
    @include media-breakpoint-down(sm) {
      .service_img {
        min-height: 150px;
        max-height: 150px;
      }

      //   .service-title,
      //   .service-description {
      //     min-height: unset;
      //   }
    }
  }
}
