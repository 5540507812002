@import "assets/styles/variables/";

.review-card {
  overflow: visible;
  margin-top: 5px;
  padding-bottom: 13px;
  .user-image {
    display: inline-block;
    width: 90px;
    height: 90px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .rating-stars {
    svg {
      width: 13px;
      @include media-breakpoint-up(xl) {
        width: auto;
      }
    }
  }

  &::before {
    width: 32px;
    height: 24px;
    background-image: url(../../../../public/images/qoute.svg);
    background-repeat: no-repeat;

    content: "";
    display: block;
    position: absolute;
    right: 20px;
    top: -5px;
  }

  &:lang(rtl) {
    &::before {
      right: auto;
      left: 20px;
    }
  }
}
